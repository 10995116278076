import React       from "react"
import Layout      from "../components/layout"
import Para        from "../components/paragraph"

const TXT1 = <p>Bei  einem Spaziergang auf dem Deich liegt Ihnen der Jadebusen mit seinen einmaligen
  Salzwiesen zu Füßen. <br />
  See- und Wattgeruch, verbunden mit dem unendlichen Blick über die Landschaft,
  lassen die Ruhe und Entspannung, die diese Region ausstrahlt, auf die Urlauber
übergehen.</p>
const TXT2 = <p>Die Umgebung lädt zu vielen Gestaltungsmöglichkeiten ein
								 <br /> wie z. B.:
								Spaziergänge<br />
										- Radwanderungen, <br />
										- interessante Besichtigungen oder<br />
										- ein Badetag in/an der Nordsee</p>
const TXT3 = <p>Ferien an der Nordsee - eine schöne Zeit erwartet Sie.</p>

const Attractions = () => (
	<Layout head_gif = "seeblick_bhb.png">
	  <Para txt="Hinterm Deich"      my_img="hintermdeich.jpg" my_txt={TXT1}/>
		<Para txt="Die Maade"          my_img="diemaade.jpg"     my_txt={TXT2}/>
	  <Para txt="Der Ems-Jade Kanal" my_img="kanal.jpg"        my_txt={TXT3}/>
	</Layout>
)
export default Attractions;
